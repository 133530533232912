
    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat'), url('Montserrat-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: normal;
    src: local('Montserrat Italic'), url('Montserrat-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: local('Montserrat Thin'), url('Montserrat-Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Light';
    font-style: normal;
    font-weight: 300;
    src: local('Montserrat Light'), url('Montserrat-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat Light Italic';
    font-style: italic;
    font-weight: 300;
    src: local('Montserrat Light Italic'), url('Montserrat-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Medium'), url('Montserrat-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: local('Montserrat Medium Italic'), url('Montserrat-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: local('Montserrat SemiBold'), url('Montserrat-SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    src: local('Montserrat SemiBold Italic'), url('Montserrat-SemiBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    src: local('Montserrat Bold'), url('Montserrat-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: bold;
    src: local('Montserrat Bold Italic'), url('Montserrat-BoldItalic.woff') format('woff');
    }
    