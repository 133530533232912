#w-menu .cont-btns button {
    width: 400px;
    height: 400px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: block;
    text-align: center;
    background: #ffffff;
    margin: 0 auto 30px;
    -webkit-box-shadow: 8px 8px 8px 0px rgb(0 0 0 / 33%);
    box-shadow: 8px 8px 8px 0px rgb(0 0 0 / 33%);
}

.wrapper {
    transition: all 0.3s ease;
    margin: 0;
    position: relative;
    height: 100vh;
    border-top: 10px solid transparent;
    border-image: url(../../assets/img/top.png) 10;
    overflow: auto;
}