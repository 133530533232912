@import "mixins";

.row {
	width: 100%;
	clear: both;
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 0;
}
.f-r {
	float: right;
}
.f-l {
	float: left;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 16px;
	padding-left: 16px;
}

.col-auto {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-1 {
	-ms-flex: 0 0 8.333333%;
	flex: 0 0 8.333333%;
	max-width: 8.333333%;
}

.col-2 {
	-ms-flex: 0 0 16.666667%;
	flex: 0 0 16.666667%;
	max-width: 16.666667%;
}

.col-3 {
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}

.col-4 {
	-ms-flex: 0 0 33.333333%;
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
}

.col-5 {
	-ms-flex: 0 0 41.666667%;
	flex: 0 0 41.666667%;
	max-width: 41.666667%;
}

.col-6 {
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.col-7 {
	-ms-flex: 0 0 58.333333%;
	flex: 0 0 58.333333%;
	max-width: 58.333333%;
}

.col-8 {
	-ms-flex: 0 0 66.666667%;
	flex: 0 0 66.666667%;
	max-width: 66.666667%;
}

.col-9 {
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
}

.col-10 {
	-ms-flex: 0 0 83.333333%;
	flex: 0 0 83.333333%;
	max-width: 83.333333%;
}

.col-11 {
	-ms-flex: 0 0 91.666667%;
	flex: 0 0 91.666667%;
	max-width: 91.666667%;
}

.col-12 {
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

.offset-1 {
	margin-left: 8.333333%;
}

.offset-2 {
	margin-left: 16.666667%;
}

.pl-0 {
	padding-left: 0;
}

.pr-0 {
	padding-right: 0 !important;
}
.pr-4 {
	padding-right: 4px !important;
}

.pl-8 {
	padding-left: 8px !important;
}
.pl-16 {
	padding-left: 16px !important;
}
.pr-8 {
	padding-right: 8px !important;
}
.pr-16 {
	padding-right: 16px !important;
}

.pt-8 {
	padding-top: 8px !important;
}

.pt-16 {
	padding-top: 16px !important;
}

.pt-23 {
	padding-top: 23px !important;
}
.pt-32 {
	padding-top: 32px !important;
}
.pb-0 {
	padding-bottom: 0px !important;
}
.pb-8 {
	padding-bottom: 8px !important;
}
.pb-16 {
	padding-bottom: 16px !important;
}

.pb-32 {
	padding-bottom: 32px !important;
}
.mr-4 {
	margin-right: 4px !important;
}
.mr-8 {
	margin-right: 8px !important;
}
.mr-16 {
	margin-right: 16px !important;
}
.mr-32 {
	margin-right: 32px !important;
}

.ml-4 {
	margin-left: 4px !important;
}
.ml-8 {
	margin-left: 8px !important;
}
.ml-16 {
	margin-left: 16px !important;
}
.ml-32 {
	margin-left: 32px !important;
}
.mt-8 {
	margin-top: 8px !important;
}
.mt-16 {
	margin-top: 16px !important;
}
.mt-32 {
	margin-top: 32px !important;
}
.mt-50 {
	margin-top: 50px !important;
}
.mt-32 {
	margin-top: 32px !important;
}
.mb-0 {
	margin-bottom: 0px !important;
}
.mb-8 {
	margin-bottom: 8px !important;
}
.mb-16 {
	margin-bottom: 16px !important;
}
.mb-32 {
	margin-bottom: 32px !important;
}
.m-auto {
	margin: auto;
}
.txt-right {
	text-align: right !important;
}

.txt-left {
	text-align: left !important;
}

.txt-center {
	text-align: center !important;
}
