@import url(../fonts/montserrat/montserrat.css);
@import "normalize";
@import "variables";
@import "layout";

button{
	transition: all 0.3s ease;
	background: transparent;
}

body {
	font-family: "Montserrat", sans-serif;
	background: $gris-bg;
	color: $txtGrisOscuro;
	font-size: 16px;
	font-weight: 300;
	overflow: hidden;
	&.handicap {
		.wrapper{
			margin: 500px 0 0 0 !important;
			overflow: auto;
			height: calc(100vh - 650px);
			#header{
				display: none;
			}
		}
		.handicap-space{
			height: 500px;
		}
		#wrap-minusv{
			bottom: 0;
			#act-minusv{
				.ico-m{
					display: none;
				}
				.ico-c{
					display: block;
				}
			}
		}
	}
}
.handicap-space {
    display: block;
    width: 100%;
    height: 0;
    background-image: url(../img/bg_accesibilidad.png);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #131415;
    position: fixed;
    top: 0;
    z-index: 5;
	transition: all 0.3s ease;
}
.wrapper{
	transition: all 0.3s ease;
	margin: 0;
	position: relative;
	height: 100vh;
	border-top: 10px solid transparent;
	border-image: url(../img/top.png) 10;

}

#video {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
}
#w-descanso{
	.logo{
		position: absolute;
		z-index: 1;
		top: 165px;
		left: calc(50% - 135px);
	}
	button{
		position: absolute;
		z-index: 1;
		width: 630px;
		height: 156px;
		left: calc(50% - 315px);
		bottom: 300px;
		@include border-radius(10px);
		background: rgb(218,170,87);
background: linear-gradient(180deg, rgba(218,170,87,1) 0%, rgba(174,133,62,1) 100%);
		span{
			font-size: 50px;
			font-weight: bold;
			color: $white;
		}
		&:focus{
			background: rgb(38,38,38);
background: linear-gradient(1800deg, rgba(38,38,38,1) 0%, rgba(38,38,38,1) 100%);
		}
	}
}
#w-menu{
	background-image: url(../img/bg-menu.jpeg);
	.logo{
		position: absolute;
		top: 180px;
		left: calc(50% - 295px);
	}
	.cont-btns{
		width: 850px;
		margin: 500px auto 0;
		float: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		button{
			width: 400px;
			height: 400px;
			@include border-radius (50%);
			display: block;
			text-align: center;
			background: $white;
			margin: 0 auto 30px;
			-webkit-box-shadow: 8px 8px 8px 0px rgba(0,0,0,0.33); 
			box-shadow: 8px 8px 8px 0px rgba(0,0,0,0.33);
			&:focus{
				background: #ccc;
			}
			img{
				margin: 0 auto 20px;
				display: block;
			}
			span{
				font-size: 45px;
				font-weight: 500;
				line-height: 1;
			}
		}
	}
}
#w-beneficios{
	.menu-lat{
		button{
			height: 220px;
			font-size: 30px;
			font-weight: 600;
		}
	}
	.contenido{
		h1{
			text-align: center;
			font-size: 40px;
		}
		img{
			margin: 20px auto;
			float: none;
			display: block;
		}
		p{
			font-size: 20px;
		}
	}
}
#w-otrosp{
	
	.menu-lat{
		button{
			height: 220px;
			font-size: 30px;
			font-weight: 600;
		}
	}
	.contenido{
		
		h1{
			text-align: center;
			font-size: 40px;
		}
		img{
			margin: 20px auto 50px;
			float: none;
			display: block;
		}
		p{
			font-size: 30px;
			text-align: center;
			width: 440px;
			margin: 0 auto 20px;
		}
	}
}
#w-modelos{
	.menu-lat{
		button{
			height: 140px;
			font-size: 30px;
			font-weight: 600;
			text-align: left;
			padding: 0 0 0 30px;
			span:nth-of-type(2){
				font-size: 20px;
				font-weight: 400;
				display: block;
				clear: both;
			}
		}
	}
	.contenido{
		background: #ffffff;
		width: calc(100% - 390px);
		float: left;
		padding: 0;
		height: 980px;
		&#cont-tipo{
			padding: 40px 50px;
			.cont-tipos{
				display: flex;
				flex-wrap:  wrap;
				justify-content: space-between;
				width: 550px;
				margin: 0 auto;
				button{
					width: 240px;
					height: 175px;
					border: 1px solid $gris-bg;
					margin: 0 auto 30px;
					&:focus{
						border: 1px solid $txtGrisOscuro;
					}
				}
			}
			.head-categoria{
				width: 590px;
				height: 45px;
				background: $gris-bg;
				border-left: 5px solid $gold;
				button{
					height: 45px;
					width: 60px;
					padding: 0 20px;
				}
				span{
					width: 520px;
					text-align: center;
					font-size: 20px;
					font-weight: 500;
					display: inline-block;
				}
			}
			.cont-sedan{
				button{
					width: 300px;
					height: 200px;
					float: none;
					margin: 100px auto;
					display: block;
					span{
						font-size: 30px;
						margin: 20px auto 0;
					}
				}
			}
		}
	}
	
}
#header{
	background: $white;
	height: 215px;
	.logos-head{
		float: left;
		margin: 80px 0 0 60px;
	}
	.cont-menu{
		float: right;
		margin: 70px 60px 0 0;
		width: 290px;
		display: flex;
		justify-content: space-between;
		button{
			text-align: center;
			
			img{
				display: block;
				clear: both;
				float: none;
				margin: 0 auto;
			}
			span{
				display: block;
				clear: both;
				font-size: 20px;
				font-weight: 500;
				color: $gold;
				float: none;
				margin: 16px auto 0;
			}
		}
		
	}
}
.cont-interior{
	.banner{
		display: block;
		float: none;
		margin: 60px auto 60px;
		text-align: center;
	}
	.menu-lat{
		width: 350px;
		float: left;
		button{
			width: 100%;
			text-align: center;
			font-size: 20px;
			background: rgb(229,229,229);
			background: linear-gradient(1800deg, rgba(229,229,229,0) 0%, rgba(220,220,220,1) 100%);
			position: relative;
			&.active, &:focus{
				color: $white;
				background: rgb(218,170,87);
				background: linear-gradient(180deg, rgba(218,170,87,1) 0%, rgba(174,133,62,1) 100%);
				border-left: 8px solid;
  				border-image: linear-gradient(180deg, $txtGrisOscuro, $gold) 1;
				::before{
					display: block;
					content: url(../img/bg-btn.png);
					width: 87px;
					height: 87px;
					position: absolute;
					right: -35px;
					top: calc(50% - 45px);
				}
			}
		}

	}
	.contenido{
		width: calc(100% - 350px);
		float: right;
		padding: 0 45px;
		&#cont-ficha{
			width: 1000px;
			margin: 0 auto;
			float: none;
			display: block;
			.tit-caract{
				border-left: 2px solid $gold ;
				font-size: 25px;
				font-weight: 300;
				padding: 5px 10px;
				margin: 0 0 30px;
				float: left;
			}
			.img-ficha-ext-int{
				margin: 10px auto;
				float: none;
				display: block;
			}
			.panel{
				.accesorios{
					padding: 50px 80px;
					p{
						font-size: 30px;
					}
					.acc-img{
						float: left;
					}
					.acc-qr{
						float: right;
					}
					.acc-varios{
						margin: 30px auto 0;
					}
				}
			}
			.cont-slide {
				width: 1000px;
				margin: 0 auto 50px;
				.slick-list.draggable {
					margin: 0 auto;
					width: 90%;
					padding: 0;
				}
				.slick-slide{
					padding: 80px 20px; 
					margin: 0 50px;
					transform: scale(1);
					transition: all 0.3s ease;
					&.slick-current{
						transform: scale(2.5);
					}
				}
				.slick-prev, .slick-next{
					width: 100px;
					height: 100px;
					background: transparent;
					top: calc(50% - 50px);
				}
				.slick-prev:before, .slick-next:before{
					color: #7d7d7d;
    			font-size: 50px;
				}
			}
		}
	}
}

.accordion-n1 {
	font-size: 20px;
	font-weight: 500;
	background-color: $bg-accordion;
	color: $txtGrisOscuro;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	text-align: left;
	border: none;
	outline: none;
	transition: 0.4s;
	position: relative;
	&::before{
		content: "";
		width: 20px;
		height: 4px;
		background: $gold;
		position: absolute;
		right: 10px;
		top: 25px;
		display: block;
	}
	&::after{
		content: "";
		width: 20px;
		height: 4px;
		background: $gold;
		position: absolute;
		right: 10px;
		top: 25px;
		display: block;
		transform: rotate(90deg);
		transition: all 0.2s ease;
	}
  }

  .accordion-n1.active {
	background: rgb(217,217,217);
background: linear-gradient(90deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 100%);
	&::after{
		transform: rotate(0deg);
	}
  }
  .panel {
	padding: 0;
	background-color: white;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
  }
  .panel.active{
	max-height: 100vh;
  }
#specsGroups{
	background: $white;
	.cabecera{
		.tit-modelo{
			background: #ccc;
			font-size: 14px;
    padding: 20px 10px;
			span{
				display: block;
			}
		}
	}
	&:before, &:after{
		display: none;
	}
	.data{
		
		.accordion-item{
			.accordion{
				background-color: gray;
				color: #fff;
				width: 100%;
				text-align: left;
				padding: 10px 20px;
				font-weight: 600;
				}
				.table-container {
					display: none;
					&.active{
						display: block;
					}
					.table{
						width: 100%;
						border: none;
						border-collapse: collapse;
						tbody{
							tr{
								td{
									padding: 10px 10px;
									font-size: 18px;
									font-weight: 400;
									&:nth-of-type(1){
										font-weight: 500;
									}
								}
							}
						}
					}
					.table-striped{
						border-collapse: collapse;
						tbody{
							tr:nth-of-type(odd){
								background-color: rgba(0,0,0,.05);
							}
						}
					}
				}
			}
		}
	}

  
/* Style the tab */
.tab {
	display: block;
	border-top: 2px solid $white;
	background-color: #f1f1f1;
	height: 50px;
  }
  
  .tab button {
	background-color: inherit;
	float: left;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 14px 16px;
	transition: 0.3s;
	width: 50%;
	position: relative;
	&::before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 15px 15px 0 15px;
		border-color: transparent transparent transparent transparent;
		position: absolute;
		bottom:-15px;
		left: calc(50% - 15px);
		transition: all 0.2s ease;
	}
  }


  .tab button.active {
	background-color: $gold;
	color: $white;
	&::before {
		display: block;
		border-color: $gold transparent transparent transparent;
	}
  }

  .tabcontent {
	display: none;
	padding: 35px 20px;
	border-top: none;
  }


.equipo-salfa{
	display: block;
	margin: 100px auto 0;
	float: none;
	-webkit-box-shadow: 4px 0px 4px 0px rgba(0,0,0,0.25); 
	box-shadow: 4px 0px 4px 0px rgba(0,0,0,0.25);
}
.cont-sellos-salfa{
	width: 780px;
	display: flex;
	justify-content: space-between;
	margin: 100px auto;
	float: none;
	.item-sello {
		width: 170px;
		text-align: center;
		font-size: 20px;
		span{
			display: block;
			margin: 20px auto 0;
			max-width: 160px;
		}
	}
}
.cont-qr{
	width: 780px;
	margin: 0 auto;
	float: none;
	.descripcion{
		font-size: 25px;
		font-weight: 300;
		padding: 10px;
		border-left: 2px solid $gold ;
		float: left;
		margin: 0 30px 0 0;
	}
	.escanea{
		float: left;
	}
	.cod-qr{
		float: right;
	}
}

#wrap-minusv{
	width: 100%;
    background: #262626;
    padding: 30px;
    height: 150px;
    position: absolute;
    z-index: 20;
    display: block;
    bottom: -110px;
	#act-minusv{
		width: 60px;
		height: 60px;
		@include border-radius (50%);
		background: $gold;
		position: absolute;
		right: 45px;
		top: -30px;
		img{
			margin: 0 auto;
		}
		.ico-m{
			display: block;
		}
		.ico-c{
			display: none;
		}
	}
	.cont-btns{
		width: 750px;
		display: flex;
		justify-content: space-between;
		float: none;
    	margin: 10px auto 0;
		transition: all 0.3s ease;
		button{
			text-align: center;
			color: $white;
			img{
				display: block;
				clear: both;
				float: none;
				margin: 0 auto 16px;
			}
			span{
				font-size: 20px;
				font-weight: 500;
			}
		}
	}
}
