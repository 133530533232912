@mixin border-radius($radius: 5px) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin box-shadow {
	-webkit-box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
}
@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
	border-top-left-radius: $topleft;
	border-top-right-radius: $topright;
	border-bottom-right-radius: $bottomright;
	border-bottom-left-radius: $bottomleft;
	-webkit-border-top-left-radius: $topleft;
	-webkit-border-top-right-radius: $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius: $bottomleft;
	-moz-border-radius-topleft: $topleft;
	-moz-border-radius-topright: $topright;
	-moz-border-radius-bottomright: $bottomright;
	-moz-border-radius-bottomleft: $bottomleft;
}
